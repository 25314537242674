.markdown-editor, .rich-select {
    /* Reset styles that might be set by form-control */
    height: auto !important;
    padding: 0 !important;
    border: none !important;
}

.markdown-editor .card-header-tabs {
    margin: 0 0 0.5rem 0;
    border-bottom: 1px solid #ecf0f1;
}

.markdown-editor .nav-link {
    padding: 0.3rem 1rem;
}

.markdown-editor-preview {
    min-height: 100px;
    padding: 0.25rem 0.75rem;
}

.markdown-editor-preview > :last-child {
    margin-bottom: 0;
}

.btn:disabled {
    cursor: not-allowed !important;
}

.tags-input-container{
    border: 1px solid #c7c7c7;
    padding: .5em;
    border-radius: 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
}

.tag-item{
    background-color: rgb(218, 216, 216);
    display: inline-block;
    padding: .5em .75em;
    border-radius: 20px;
}
.tag-item .close{
    height: 20px;
    width: 20px;
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 18px;
    cursor: pointer;
}

.tags-input{
    flex-grow: 1;
    padding: .5em 0;
    border: none;
    outline: none;
}