.quota-card table {
    margin-bottom: 0 !important;
}

.quota-card table th, .quota-card table td {
    padding: 0.5rem 1rem !important;
    white-space: nowrap;
}

.quota-card table th {
    /* For table cells, width behaves like min-width */
    width: 1%;
    text-align: right;
}
