@media (min-width: 1600px) {
    .col-xxl-12 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .col-xxl-11 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 91.6666666667% !important;
        flex: 0 0 91.6666666667% !important;
        max-width: 91.6666666667% !important;
    }
    .col-xxl-10 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 83.3333333333% !important;
        flex: 0 0 83.3333333333% !important;
        max-width: 83.3333333333% !important;
    }
    .col-xxl-9 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 75% !important;
        flex: 0 0 75% !important;
        max-width: 75% !important;
    }
    .col-xxl-8 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 66.6666666667% !important;
        flex: 0 0 66.6666666667% !important;
        max-width: 66.6666666667% !important;
    }
    .col-xxl-7 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 58.3333333333% !important;
        flex: 0 0 58.3333333333% !important;
        max-width: 58.3333333333% !important;
    }
    .col-xxl-6 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 50% !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .col-xxl-5 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 41.6666666667% !important;
        flex: 0 0 41.6666666667% !important;
        max-width: 41.6666666667% !important;
    }
    .col-xxl-4 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 33.3333333333% !important;
        flex: 0 0 33.3333333333% !important;
        max-width: 33.3333333333% !important;
    }
    .col-xxl-3 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 25% !important;
        flex: 0 0 25% !important;
        max-width: 25% !important;
    }
    .col-xxl-2 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 16.6666666667% !important;
        flex: 0 0 16.6666666667% !important;
        max-width: 16.6666666667% !important;
    }
    .col-xxl-1 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 8.3333333333% !important;
        flex: 0 0 8.3333333333% !important;
        max-width: 8.3333333333% !important;
    }

    .row-cols-xxl-1 > * {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .row-cols-xxl-2 > * {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 50% !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .row-cols-xxl-3 > * {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 33.3333333333% !important;
        flex: 0 0 33.3333333333% !important;
        max-width: 33.3333333333% !important;
    }
    .row-cols-xxl-4 > * {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 25% !important;
        flex: 0 0 25% !important;
        max-width: 25% !important;
    }
    .row-cols-xxl-5 > * {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 20% !important;
        flex: 0 0 20% !important;
        max-width: 20% !important;
    }
    .row-cols-xxl-6 > * {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 16.6666666667% !important;
        flex: 0 0 16.6666666667% !important;
        max-width: 16.6666666667% !important;
    }
    .row-cols-xxl-7 > * {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 14.2857142857% !important;
        flex: 0 0 14.2857142857% !important;
        max-width: 14.2857142857% !important;
    }
    .row-cols-xxl-8 > * {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 12.5% !important;
        flex: 0 0 12.5% !important;
        max-width: 12.5% !important;
    }
    .row-cols-xxl-9 > * {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 11.1111111111% !important;
        flex: 0 0 11.1111111111% !important;
        max-width: 11.1111111111% !important;
    }
    .row-cols-xxl-10 > * {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 10% !important;
        flex: 0 0 10% !important;
        max-width: 10% !important;
    }
    .row-cols-xxl-11 > * {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 9.0909090909% !important;
        flex: 0 0 9.0909090909% !important;
        max-width: 9.0909090909% !important;
    }
    .row-cols-xxl-12 > * {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 8.3333333333% !important;
        flex: 0 0 8.3333333333% !important;
        max-width: 8.3333333333% !important;
    }
}
